.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: visible;
  max-width: calc(100vw - 40px);
  background-color: #282829;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 130%;
  white-space: nowrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #282829 transparent transparent;
}

.custom-input-search::placeholder {
  color: white !important;
  opacity: 1; /* Ensure the color is not transparent */
}

.custom-input-container .ant-input::placeholder {
  color: white !important;
  opacity: 1;
}
.time-range-select {
  background: black;
}

.time-range-select .ant-select-selector {
  background: black !important;
  color: white !important;

  .ant-select-selector {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px !important;
  }
  font-weight: 600 !important;
  font-size: 14px !important ;
  padding: 20px !important;
  color: white !important;
}

.time-range-select .ant-select-arrow {
  color: white;
}

@media (max-width: 450px) {
  .time-range-select .ant-select-selector {
    font-weight: 500 !important;
    font-size: 12px !important ;
    padding: 10px !important;
    color: white !important;
  }
}
