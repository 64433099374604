@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('../../node_modules/react-intl-tel-input/dist/main.css');
/* TextLayer styles for rendering text properly */
@import 'react-pdf/dist/esm/Page/TextLayer.css';

/* AnnotationLayer styles for rendering annotations like links or highlights */
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

body {
  background-color: #000000;
  cursor: default;
  touch-action: manipulation;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.parent-container {
  height: 100%;
  overflow-y: hidden;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ant-radio-wrapper .ant-radio-inner {
  background-color: #000000 !important;
  border: 1.7px solid white;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: transparent !important;
  border: 1.7px solid white;
}

.custom-radio-wrapper .ant-radio-inner {
  width: 23px; /* Adjust width */
  height: 23px; /* Adjust height */
}

.custom-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: transparent !important;
  border: 1.6px solid white !important;
}

.custom-radio-wrapper .ant-radio-inner::after {
  width: 11px;
  height: 11px;
  top: 50%;
  left: 50%;
  transform: translate(25%, 25%) !important;
}

.custom-radio-wrapperCod .ant-radio-inner::after {
  width: 12px; /* Adjust inner circle width */
  height: 12px; /* Adjust inner circle height */
  top: 50%;
  left: 50%;
  transform: translate(19%, 15%) !important;
}
@media (max-width: 450px) {
  .ant-form-item-explain-error {
    font-size: 12px !important;
  }
  .custom-radio-wrapper .ant-radio-inner {
    width: 20px; /* Adjust width */
    height: 20px; /* Adjust height */
  }
  .custom-radio-wrapper .ant-radio-inner::after {
    transform: translate(26%, 26%) !important;
    width: 10px; /* Adjust inner circle width */
    height: 10px;
  }

  .custom-radio-wrapperCod .ant-radio-inner::after {
    transform: translate(26%, 28%) !important;
    width: 10px; /* Adjust inner circle width */
    height: 10px;
  }
}

.resetCollapse {
  .ant-collapse-header {
    // padding: 0px !important;
    border-radius: 0px !important;
  }
  .ant-collapse-content {
    border-radius: 0px;
  }
}

.ant-input-outlined {
  background: #000000;
}
.ant-input-outlined:focus-within {
  background-color: #000000;
}
.ant-input-outlined:hover {
  background-color: #000000;
}

.makeBgBlack {
  .ant-input-outlined {
    background: #000000;
  }
  .ant-input-outlined:focus-within {
    background-color: #000000;
  }
  .ant-input-outlined:hover {
    background-color: #000000;
  }
  .input.ant-input:focus {
    background-color: #000000;
  }
  .ant-input:focus {
    background-color: #000000;
    background: #000000;
  }
  font-size: 16px !important;
}

.customTab {
  .ant-tabs-nav::before {
    border-bottom: 1px solid #1e1e1e !important;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center !important;
  }
}
.ant-tabs .ant-tabs-tab-btn {
  color: #989898 !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff5612;
}
.customTabs {
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
}

.alreadyLoginCollapse {
  .ant-collapse-header {
    // padding: 0px !important;
    border-radius: 12px 12px 12px 12px !important;
    background-color: #131313;
  }
  .ant-collapse-content {
    background-color: #131313;
    border-radius: 12px 12px 12px 12px !important;

    border: 0px;
  }
  .ant-collapse-item {
    border-radius: 12px 12px 12px 12px !important;

    border: 0px;
  }
}
.ant-collapse {
  border: 0px !important;
  // background-color: white;
}

.blackInput {
  .ant-input {
    background-color: black !important;
    color: #a7a7a7;
  }
  .ant-input::placeholder {
    color: #a7a7a7;
  }
}

.login-container {
  background: url('../../assets/LoginBG.webp') no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-attachment: fixed;
  align-items: baseline;
  background-size: contain;
  background-position: bottom;
  box-sizing: border-box;
  position: relative;
}
.bookDetails-bg {
  background: url('../../assets/BookBackgroundImage.webp') no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-attachment: fixed;
  align-items: baseline;
  background-size: contain;
  background-position: bottom;
  box-sizing: border-box;
  position: relative;
}

.background-container {
  background: url('../../assets/Icons/Subtract.svg') no-repeat;
  min-width: 350px; /* Set a minimum width */
  min-height: 200px; /* Set a minimum height */
  display: flex;
  justify-content: center;
  background-size: contain;
  background-position: center;
  position: relative;
  padding: 30px;
}

@media (max-width: 450px) {
  .background-container {
    min-width: auto;
    background: none;
    min-height: auto;
    padding: 10px 10px;
    background-size: cover !important;
    box-sizing: border-box !important;
  }
}
.card {
  box-shadow: 0px 0px 15px #3e445726;
}

.antdBorder {
  border: none !important;
}

.antDBorderBottom {
  border-bottom: 1px solid #555555 !important;
}

.padding {
  .ant-collapse-content-box {
    background-color: #e5e7eb !important;
  }
}

.bgCollapse {
  .ant-collapse-content-box {
    background-color: #f1f1f1 !important;
  }
}

.cardShadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.paddingNone {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;

    // opacity: 0.5 !important;
    // backdrop-filter: blur(10px) !important;
  }
}
.drawerPaddingNoScrollBar {
  .ant-drawer-body::-webkit-scrollbar {
    display: none !important;
    width: 0px !important;
    background: transparent;
  }
}
.ant-drawer .ant-drawer-content {
  background-color: rgba(8, 8, 8, 0.8);
  // opacity: 0.75;
  backdrop-filter: blur(4px);
  z-index: 999; /* Adjust the value as needed */
}

.inputTextCenter {
  .ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group-addon {
    padding: 0 !important;
  }
}

.inputTextColor {
  .ant-input-number-input {
    background-color: #000000 !important;
    border: none !important;
  }
  .ant-input-number-input:disabled {
    background-color: #000000 !important;
    color: white;
    cursor: default !important;
    border: none !important;
  }
  .ant-input-number:not(:first-child):not(:last-child) {
    background-color: #000000 !important;
    border: none !important;
  }
}

.antdSelect {
  .ant-select-selector {
    border-radius: 0;
    // border: 1px solid #5d646e !important;
  }
}

.removeArrow {
  .react-code-input {
    input[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.processBar {
  .ant-progress-bg {
    background-color: #ff5612;
    cliprule: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    border-radius: 0% !important;
  }
  .ant-progress-inner {
    border-radius: 0% !important;
  }
}

.customSearch {
  .ant-input {
    background-color: #212121;
    color: #999999 !important;
  }
  .ant-input::placeholder {
    color: #999999 !important;
  }
}

.customSideBar {
  .ant-layout-sider-children {
    background-color: black !important;
    border-right: 2px solid #212121;
    position: fixed;
    width: 200px;
  }
}

.customSideBarMenu {
  .ant-menu-item-selected {
    background-color: #212121 !important;
  }
}

.customMaskModal {
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .ant-modal-content {
    padding: 0 !important;
    background-color: #121212;
  }
}

.card {
  box-shadow: 0px 0px 15px #3e445726;
}
.ant-btn-primary {
  background-color: #ff5612 !important;
}

.custom-menu-item:hover,
.custom-menu-item.ant-dropdown-menu-item-active,
.custom-menu-item.ant-dropdown-menu-submenu-title:hover,
.custom-menu-item.ant-dropdown-menu-submenu-title-active {
  background-color: transparent !important;
}
.customRangePicker {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: flex !important;
    flex-direction: column !important;
  }
  .ant-picker-panel {
    display: flex !important;
    flex-direction: column !important;
  }
}

.customTable {
  .ant-table-thead th {
    background-color: white !important;
    border-bottom: 1px solid black !important;
  }
  .ant-table-row td {
    border-bottom: 1px solid rgb(157, 157, 157) !important;
  }
}

.antdBorder {
  border: none !important;
}

.antdBorderProject {
  .ant-collapse-content-box {
    background-color: #fafafa !important;
  }
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-left: none !important;
  border-right: none !important;
}

.antDBorderBottom {
  border-bottom: 1px solid #555555 !important;
}

.padding {
  .ant-collapse-content-box {
    background-color: #e5e7eb !important;
  }
}

.bgCollapse {
  .ant-collapse-content-box {
    background-color: #f1f1f1 !important;
  }
}

.cardShadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.paddingNone {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

.inputTextCenter {
  .ant-input-number-input {
    text-align: center;
    width: 55px;
  }
  .ant-input-number-group-addon {
    padding: 0 !important;
    border: none !important;
  }
}

.antdSelect {
  .ant-select-selector {
    // border-radius: 10px !important;
    background-color: #000000 !important;
    color: #ffffff !important;
    // border: 1px solid #3a3a3a !important;
    font-size: 16px;
    padding-left: 12px !important;
  }
  .ant-select-arrow {
    color: #ffffff !important;
  }
  .ant-select-dropdown-menu-item {
    color: #ffffff !important;
  }
  .ant-select-dropdown {
    background-color: #131313 !important;
    color: #ffffff !important;
  }
  .ant-select-selection-placeholder {
    color: #a7a7a7 !important; /* Change this to your desired placeholder color */
  }
}
.ant-select-dropdown-menu-item,
.ant-select-item-option {
  background-color: #131313 !important;
  color: #ffffff !important;
}
.ant-select-dropdown-menu-item:hover,
.ant-select-item-option:hover {
  background-color: #242424 !important;
}

.removeArrow {
  .react-code-input {
    input[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.processBar {
  .ant-progress-bg {
    background-color: #ff5612;
    cliprule: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    border-radius: 0% !important;
  }
  .ant-progress-inner {
    border-radius: 0% !important;
  }
}

.hideText {
  .react-pdf__Page__textContent {
    display: none !important;
  }
  .react-pdf__Page__annotations {
    display: none !important;
  }
}
.customScrollBar {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #666;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.custom-input {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr); /* Default for large screens (col-6) */
  justify-content: center;
  width: 10px !important;
  height: 100%;
  border: none;
  -moz-appearance: textfield !important;
}
.custom-input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.custom-input[type='number']::-webkit-inner-spin-button,
.custom-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Responsive styles */
@media (max-width: 600px) {
  /* Mobile devices (col-2) */
  .custom-input {
    grid-template-columns: repeat(3, 1fr);
    width: 40px;
  }
}
.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-scrollbar {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    display: none; /* WebKit (Chrome, Safari, etc.) */
  }
}
.ant-skeleton-content {
  .ant-skeleton
    .ant-skeleton-element
    .ant-skeleton-active
    .ss-dev-only-do-not-override-1j1oynk {
    background: white;
    color: #c8adad;
  }
}
.card {
  box-shadow: 0px 0px 15px #3e445726;
}
.antdBorder {
  border: none !important;
}
.antDBorderBottom {
  border-bottom: 1px solid #555555 !important;
}
.padding {
  .ant-collapse-content-box {
    background-color: #e5e7eb !important;
  }
}
.bgCollapse {
  .ant-collapse-content-box {
    background-color: #f1f1f1 !important;
  }
}
.cardShadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.paddingNone {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}
.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;
  }
}
.inputTextCenter {
  .ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group-addon {
    padding: 0 !important;
  }
}

.stepTheme {
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: white !important;
    // color: white !important;
    .anticon .anticon-check .ant-steps-finish-icon {
      color: #121212;
    }
    .ant-steps-item-title {
      font-size: small;
      font-weight: bold;
      color: white;
    }
  }
}
.ant-steps-item-title {
  // width: 60px;
  text-align: center;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.ant-steps-item .ant-steps-item-finish {
  .ant-steps-item-container {
    // background-color: black;
    border: 2px solid #571900 !important;
  }
}
.customStepWhite {
  .ant-steps-item .ant-steps-item-finish {
    .ant-steps-item-container {
      background-color: white !important;
      border: 2px solid #571900;
    }
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    border: none !important;
    background-color: white;
    border: 2px solid #571900;
    align-items: center !important;
    color: black !important;
    top: 10px !important;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: black !important;
  }
}
.ant-steps-item-finish .ant-steps-item-tail::after {
  border-top: 2px solid #571900; /* Change the color and thickness as needed */
}
.ant-steps-item-finish .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.45) !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  border: none !important;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border: 2px solid #2b2b2b !important;
  color: #2b2b2b !important;
  background-color: black !important;
}
.ant-steps-item-wait .ant-steps-item-icon {
  border: 2px solid #ce5522 !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  font-weight: 700 !important;
  color: black !important;
}
.ant-steps-item .ant-steps-item-process .ant-steps-item-active {
  .ant-steps-icon {
    color: black !important;
    border: none !important;
    font-weight: 700 !important;
  }
}

.antdSelect {
  .ant-select-selector {
    border-radius: 8px !important;
    border-color: #313131 !important;
  }
  .ant-select-selector::placeholder {
    color: #a7a7a7 !important;
  }
}
.removeArrow {
  .react-code-input {
    input[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}
.processBar {
  .ant-progress-bg {
    background-color: #ff5612;
    cliprule: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    border-radius: 0% !important;
  }
  .ant-progress-inner {
    border-radius: 0% !important;
  }
}
.customSearch {
  .ant-input {
    background-color: #141414;
    color: #999999 !important;
  }
  .ant-input::placeholder {
    color: #999999 !important;
  }
}
.ant-layout {
  background-color: #212121;
  overflow: auto !important;
}
.customSideBar {
  .ant-layout-sider-children {
    background-color: #121212 !important;
    border-right: 2px solid #212121;
    position: fixed;
    width: 200px;
  }
}
.customSideBarMenu {
  .ant-menu-item-selected {
    background-color: #212121 !important;
  }
}
.customMaskModal {
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .ant-modal-content {
    padding: 0 !important;
    background-color: #121212;
  }
}
.ql-container.ql-snow {
  border: none !important;
}
.customDrawer {
  .ant-drawer .ant-drawer-body {
    padding: 0px !important;
  }
}
.ant-drawer-body {
  padding: 0px !important;
  // background-color: #121212;
}
.ant-drawer-header {
  // padding: 0px !important;
}

@media print {
  .pdf-container {
    display: none;
  }
}

.disabled-section {
  opacity: 0.5; /* Adjust the opacity to visually "gray out" the section */
  pointer-events: no; /* Disable pointer events to make it non-interactive */
  /* Add any additional styling for a disabled look */
}

.borderColorSelect {
  .ant-select-selector {
    border-color: #ff5612 !important;
  }
}

//v2

.hover-svg:hover .icon-container {
  filter: brightness(1.5); /* Adjust the brightness for the hover effect */
}

/* Styles for the SVG paths color on hover */
.hover-svg:hover .icon-container svg path {
  stroke: #ff5612; /* Replace with your desired hover color */
}

::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.customLoginModal {
  .ant-modal-content {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.customMaskModal {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7); /* Darker mask color */
    backdrop-filter: blur(200px) !important; /* Apply blur effect */
  }
}

.customModalNoPadding {
  .ant-modal-content {
    padding: 0 !important;
  }
}

.customHeight {
  .ant-modal-content {
    height: 100% !important;
  }
}

.customInput {
  .ant-input.ant-input-lg.css-dev-only-do-not-override-dkbvqv {
    background-color: #000000 !important;
    color: #a7a7a7;
    padding: 10px;
    &:focus,
    &:active {
      background-color: #000000 !important;
    }

    &:-webkit-autofill {
      /* Styles for WebKit browsers (Chrome, Safari) autofill */
      background-color: #000000 !important;
      color: #a7a7a7 !important;
    }

    &:-moz-placeholder {
      /* Styles for Gecko (Firefox) autofill */
      background-color: #000000 !important;
      color: #a7a7a7 !important;
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #000000 inset !important; /* Adjust the color as needed */
    }
  }
  input.ant-input:focus {
    background-color: #000000 !important;
  }
  .ant-input.ant-input-lg.css-dev-only-do-not-override-dkbvqv::placeholder {
    color: #a7a7a7;
  }
  .anticon.ant-input-password-icon {
    color: #a7a7a7 !important;
  }
}
.input.ant-input:focus {
  background-color: #000000 !important;
}
.ant-divider-horizontal.ant-divider-with-text::after {
  background-color: white;
}
.ant-divider-horizontal.ant-divider-with-text::before {
  background-color: white;
}

.customProcessBar {
  .ant-progress-inner {
    background-color: #363636 !important;
    height: 6px !important;
  }
  .ant-progress-bg {
    background-color: #ff5612 !important;
    height: 5px !important;
    border-radius: 10px 0px 0px 10px !important;
  }
}

.customProcessBarHeight {
  .ant-progress-inner {
    height: 6px !important;
  }
  .ant-progress-bg {
    height: 5px !important;
    border-radius: 10px 0px 0px 10px !important;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.main,
.sidebar {
  border: 3px solid black;
  padding: 15px;
  background-color: #fff;
}

.main {
  width: 60%;
  height: 150vh;
}

.sidebar {
  width: 25%;
  height: 25vh;
}
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.customInputBlack {
  .ant-input {
    background-color: #000000 !important;
    color: #ffffff;
    padding: 5px !important;
    font-size: 16px !important;
    &:focus,
    &:active {
      background-color: #000000 !important;
    }

    &:-webkit-autofill {
      /* Styles for WebKit browsers (Chrome, Safari) autofill */
      background-color: #000000 !important;
      color: #ffffff !important;
    }

    &:-moz-placeholder {
      /* Styles for Gecko (Firefox) autofill */
      background-color: #000000 !important;
      color: #646464 !important;
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #000000 inset !important; /* Adjust the color as needed */
    }
    input.ant-input:focus {
      background-color: #141414 !important;
    }
    .ant-input-outlined:hover {
      background-color: #000000 !important;
    }
    .ant-input-outlined {
      background: #000000 !important;
    }
    .ant-input-outlined:focus-within {
      background-color: #000000 !important;
    }
  }

  .ant-input::placeholder {
    color: #a7a7a7;
  }
  .anticon.ant-input-password-icon {
    color: #a7a7a7 !important;
  }
}

// Pagination
.ant-pagination .ant-pagination-item a {
  color: #ffffff !important;
}
.ant-pagination .ant-pagination-item-active a {
  color: #ff5612 !important;
}

.ant-pagination .ant-pagination-next button {
  color: #ffffff !important;
}
.ant-select-dropdown {
  background-color: #131313 !important;
}

.ant-breadcrumb .ant-breadcrumb-separator {
  color: #ffffff !important;
}

// krishna changes
.customTabProfile {
  .ant-tabs-nav::before {
    border-bottom: 1px solid #1e1e1e !important;
  }
}

// Step Border None
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border: none !important;
  background-color: black;
  border: 2px solid #571900 !important;
  align-items: center !important;
  top: 10px !important;
}

// Quill Editor
.ql-clipboard {
  display: none !important;
}

.ql-editor {
  padding: 0px !important;
}

// Antd layout herder

@media (max-width: 450px) {
  .ant-layout-header {
    padding: 0 20px;
  }
  .ant-steps-item-content {
    margin-top: 6px !important;
  }
}

// Ck Editor
.ck.ck-editor__editable_inline {
  border: none !important;
  background-color: transparent !important;
  color: white;
  padding: 0 !important;
}
.ck-content a {
  color: #007bff;
  text-decoration: underline;
  font-weight: 500;
}
.ck-content a:visited {
  color: #6c757d;
}

.ck-content a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.customCkeditor {
  .ck.ck-editor__editable_inline {
    border: none !important;
    background-color: #121212 !important;
    color: white;
  }
  .ck.ck-editor__editable_inline > :last-child {
    margin-bottom: 0 !important;
  }
  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 5px !important;
  }
  .ck-editor__editable {
    background-color: #000000 !important;
  }
}

.ck.ck-toolbar {
  border: none !important;
}

.ck-editor__editable {
  background-color: #000000 !important;
}

// Custom School CkEditor
.customCkeditorSchool {
  margin-top: 0px !important;

  .ck-content {
    p {
      color: #eeeeee;
      padding-top: 14px;
      margin: 0px !important;
      line-height: 30px;
      font-size: 18px;
      font-weight: 400;
    }

    h1,
    h2,
    h3 {
      font-weight: 700;
      padding-top: 34px;
      margin: 0px !important;
    }

    h1 {
      font-size: 26px;
      line-height: 33.8px;
    }

    h2 {
      font-size: 24px;
      line-height: 31.2px;
    }

    h3 {
      font-size: 22px;
      line-height: 28.6px;
    }

    table {
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
    }

    h4,
    h5,
    h6 {
      line-height: 1.6rem;
    }

    ul {
      padding: 0 0 0 15px !important;
      list-style-type: none !important;
      margin: 0 !important;
    }
    ol {
      padding: 0 0 0 25px !important;
    }

    ul li,
    ol li {
      color: #eeeeee;
      padding-top: 14px;
      margin: 0 !important;
      line-height: 30px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
    }
    ul li {
      padding-left: 20px;
    }

    ul li::before {
      content: '•';
      color: #eeeeee;
      font-size: 18px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-30%);
    }

    ul li:last-child,
    ol li:last-child {
      margin-bottom: 0;
    }
    img {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    // Prevent horizontal scroll on mobile
    @media (max-width: 768px) {
      table {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
      }

      // Prevent overflow for images or other large content
      img,
      iframe {
        max-width: 100%;
        height: auto;
      }

      // Avoid horizontal scrollbars on the entire CKEditor content
      overflow-x: hidden;
      word-wrap: break-word; // Prevent long words or URLs from causing overflow
    }
  }
}

// custom scroll

.scroll-primary::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}
.scroll-primary::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #343434;
}
.scroll-primary::-webkit-scrollbar {
  width: 8px;
}

// Chat Background
.chatBackgroundImage {
  background-image: url('../../assets/chatBackground/chatBackground.webp');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.AiChatBgImage {
  background-image: url('../../assets/chatBackground/AiChatBg.webp');
  background-size: 50%; /* Scale the background image to cover half the container */
  background-repeat: repeat; /* Repeat the image horizontally */
  width: 100%;
  height: 100%;
}

// Popover

.ant-popover-inner-content {
  color: white !important;
}
.ant-popover-inner {
  background-color: #1c1c1c !important;
}

// Custom Badge
// .customBadge {
.ant-badge .ant-badge-count {
  box-shadow: none !important;
  font-size: 11px;
  padding-inline: 7px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;

  color: white;
}

@media (max-width: 450px) {
  .ant-badge .ant-badge-count {
    font-size: 9px;
  }
}

.customBadge {
  .ant-badge .ant-badge-count {
    font-size: 12px !important;
  }
}
// }

.customHeight {
  height: 100% !important;
}

// HTML tags

.ck.ck-content ul,
.ck.ck-content ul {
  list-style: outside;
  padding: 15px;
}
.ck.ck-content ol {
  list-style: revert;
  padding: 15px;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h3 {
  font-size: 1.2rem;
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

h5 {
  font-size: 0.875rem;
  font-weight: 400;
}

h6 {
  font-size: 0.75rem;
  font-weight: 300;
}
// Scroll Button

/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #343434;

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 16px;
  width: 16px; /* Maintain a minimum width for the button */
  padding: 10px;
  background-position: center 6px;
  background-size: 8px; /* Adjusted size of the background image */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:disabled {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='50,00 0,50 100,50'/></svg>") !important;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 16px;
  width: 16px;
  background-position: center 6px;
  background-size: 8px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}

// Ribbon Tag Custom

.ant-ribbon-corner {
  visibility: hidden !important;
}
.ant-ribbon {
  border-radius: 4px 0px 4px 0px !important;
  inset-inline-start: 0px !important;
  top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  display: inline-block !important;
  text-transform: capitalize;
  font-size: 12px !important;
  background: linear-gradient(to bottom, #ff5612, #ff8f3d);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

@media (max-width: 450px) {
  .ant-ribbon {
    border-radius: 2px 0px 2px 0px !important;
    inset-inline-start: 0px !important;
    top: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    display: inline-block !important;
    text-transform: capitalize;
    font-size: 10px !important;
    background: linear-gradient(to bottom, #ff5612, #ff8f3d);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px #000000 inset !important;
    -webkit-text-fill-color: white !important;
    -webkit-transition-delay: 99999s !important;
    -webkit-background-origin: 99999s !important;
  }
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

// Error Font Size
.ant-form-item-explain-error {
  padding-top: 2px;
  padding-bottom: 4px;
  margin-left: 5px;
}
.ant-form-item {
  margin-bottom: 12px !important;
}

// Number Select
.intl-tel-wrapper .intl-tel-input {
  width: 100%;
  .selected-flag {
    width: 120px !important;
    gap: 10px;
    border-right: solid #313131;
    display: flex !important;
    align-items: center;
    padding: 10px;
    .selected-dial-code {
      padding: 0 !important;
      margin-left: auto;
      pointer-events: none;
    }
    .arrow {
      border-radius: 1px;
      border-color: white !important;
      pointer-events: none;
      position: absolute;
      left: 40px;
      top: 13px;
      &:after {
        content: '';
      }
    }
  }
}

.country-list {
  background: #313131 !important;
  border-radius: 10px !important;
  border-color: #313131 !important;
}

// Custom Dot
.ant-badge-dot {
  box-shadow: none !important;
  border: none !important;
  transform: translate(30%, -20%) !important;
}

.ant-collapse .ant-collapse-content {
  border-top: 1px solid #1c1c1c !important;
}

.transition-max-height {
  transition: max-height 0.5s ease-in-out;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  background: #000000 !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: white !important;
}

.ant-carousel .slick-dots li button {
  background: white !important;
}

// .ant-modal .ant-modal-content {
//   background-color: transparent;
// }

.ant-divider-vertical {
  border-inline-start: 1px solid #7d7d7d;
}

.wrap {
  max-height: 338px;
  height: 338px;
  max-width: 216px;
  width: 216px;
  margin: 0 auto;
  text-align: center;
  position: relative;

  .perspective {
    width: 100%;
    height: 100%;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
}

.book-wrap {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-out;
}

.book {
  width: 216px;
  height: 338px;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.book-back {
  width: 216px;
  height: 338px;
  position: absolute;
  top: 13px;
  left: 1px;
  right: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  transform: translateZ(-25px) translateY(-5px) translateX(-3px) scaleX(-1);
}

.title {
  // height: 357px !important;
  width: 30px;
  position: absolute;
  left: -15px;

  top: -26px;
  bottom: 0;
  margin: auto;
  // background: #ab4747;
  transform: rotateY(-86deg) translateX(-14px);
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 14px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.6s;
}

@media (min-width: 1540px) {
  .book,
  .book-back,
  .wrap {
    width: 300px; /* Increase size while keeping proportions */
    height: 418px;
    margin-top: 20px;
  }
  .book-back {
    left: -63px;
    top: 9px;
  }
  // .wrap {
  //   width: 256px; /* Increase size while keeping proportions */
  //   height: 388px;
  // }
  .title {
    /* Adjust position to match the bigger size */
    top: 45px;
  }
}

.book-wrap.flipping .title {
  opacity: 0;
}

.rotate {
  transform: rotateY(20deg);
}
.flip {
  transform: rotateY(180deg);
}

.customGradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    94deg,
    rgba(0, 0, 0, 0.6503851540616247) 22%,
    rgba(0, 0, 0, 0.2862394957983193) 64%
  );
}

.customGradientSchool {
  background: rgb(18, 18, 18);
  background: linear-gradient(
    180deg,
    rgba(18, 18, 18, 0.6223739495798319) 21%,
    rgba(18, 18, 18, 0.9669117647058824) 80%
  );
}

@media (max-width: 968px) {
  .customGradient {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6167717086834734) 0%,
      rgba(0, 0, 0, 0.7652310924369747) 11%
    );
  }
}

.customGradientMobile {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7400210084033614) 3%,
    rgba(0, 0, 0, 0.8800770308123249) 27%
  );
}

.moving-border {
  --angle: 0deg;
  --c1: #166ff6; // Main border color
  --c2: #001330; // Secondary color (transparent or background match)
  --d: 5s; // Duration of animation

  font-size: 3vw;
  margin: max(1rem, 3vw);
  padding: 3vw;
  border: 0.35rem solid;
  border-radius: 8px;
  border-image: conic-gradient(
      from var(--angle),
      var(--c1),
      var(--c2) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 360deg;
  }
}

.inner-div {
  font-size: 1.5vw;
  background-color: #001330;
  color: #237aff;
  border-radius: inherit;
  padding: 10px 20px;
  text-align: center;
}

// Like Button

.heart-animation-wrapper {
  .heart {
    width: 100px; /* Keep the original width */
    height: 100px; /* Keep the original height */
    background: url('https://cssanimation.rocks/images/posts/steps/heart.png')
      no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
    transform: scale(0.5); /* Shrink the element to 50% of its original size */
    transform-origin: top left; /* Ensure scaling happens from the top-left corner */
  }

  .heart.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }

  .stage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.customProcessPageTracking {
  .ant-progress-line {
    margin-bottom: 0px !important;
  }
}

.ant-form-item-explain {
  transition:
    opacity 0.3s ease,
    height 0.3s ease;
}

.carousel-container {
  box-shadow:
    0px 4px 4px -1px rgba(0, 0, 0, 0.1),
    0px -4px 4px -1px rgba(0, 0, 0, 0.1);
}

.pdf-viewer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin: auto;
}

/* Fullscreen simulation on iPhones */
.pdf-viewer.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 9999;
  overflow: hidden;
}

.pdf-viewer button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

// Ai Drawer
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.affix-transition {
  transition: all 0.3s ease;
}

.scrollable-feed {
  height: 100%; /* Adjust this height as necessary for your layout */
  max-height: 100%;
  overflow-y: scroll; /* Ensure this div is scrollable */

  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari, and Opera) */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.scrollable-feed::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

// Ai chat Content

.custom-text-ai-message {
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    strong {
      font-weight: 550;
      font-size: 12px;
      overflow-wrap: break-word;
      word-break: break-word;
      line-height: 22px;
    }
  }
  strong {
    font-weight: 550;
    font-size: 12px;
    overflow-wrap: break-word;
    word-break: break-word;
    line-height: 22px;
  }

  ol {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    li {
      margin-bottom: 6px;

      strong {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  ul {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    list-style-type: disc; /* Ensure bullets are shown */
    padding-left: 20px; /* Ensure indentation for bullets */

    li {
      margin-bottom: 6px;

      strong {
        font-weight: 700;
        font-size: 16px;
        word-break: break-all;
      }
    }
  }
}
.customDot {
  .ant-badge-dot {
    top: 4px !important;
    left: 62px !important;
    width: 8px !important;
    height: 8px !important;
  }
}

@media (max-width: 450px) {
  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 1920px;

    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      bottom: 0px;
      gap: 3px;
    }

    .slick-dots li {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #504f53;
      transition: all 0.3s ease;
      position: relative;
    }

    .slick-dots li.slick-active {
      width: 10px;
      height: 5px;
      border-radius: 25px;
      background-color: #fff;
      position: relative;
    }

    .slick-dots li:first-child,
    .slick-dots li:last-child {
      width: 3px;
      height: 3px;
      background-color: #504f53;
      position: relative;
    }

    .slick-dots li button {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .slick-dots li::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: inherit;
      height: inherit;
    }
  }

  .next-arrow button,
  .next-arrow button:disabled {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-text-ai-message {
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      strong {
        font-weight: 550;
        font-size: 12px;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 20px;
      }
    }
    strong {
      font-weight: 550;
      font-size: 12px;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    ol {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      li {
        margin-bottom: 6px;

        strong {
          font-weight: 700;
          font-size: 12px;
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    ul {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      li {
        margin-bottom: 6px;

        strong {
          font-weight: 700;
          font-size: 12px;
          overflow-wrap: break-word; /* Breaks long words to fit the container */
          word-break: break-word; /* Additional breaking for very long words */
        }
      }
    }
  }
  .customStepWhite {
    .ant-steps.ant-steps-small .ant-steps-item-title::after {
      top: 7px !important;
    }
  }

  .customDot {
    .ant-badge-dot {
      top: 4px !important;
      left: 50px !important;
      width: 8px !important;
      height: 8px !important;
    }
  }
}

.custom-notification-notice-content {
  background: #1f1f1f !important;
  color: rgba(255, 255, 255, 0.85);
}

@media (max-width: 768px) {
  .customDot {
    .ant-badge-dot {
      top: 4px !important;
      left: 50px !important;
      width: 8px !important;
      height: 8px !important;
    }
  }
}

@media (min-width: 370px) {
  /* Adjust the value as needed */
  .custom-tabs {
    .ant-tabs-nav-list {
      display: grid !important;
      grid-template-columns: repeat(4, 1fr) !important; /* Four equal columns */

      width: -webkit-fill-available;
    }
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
    }
  }
}
// Antd Stars

.ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 4px !important;
}

// Apply Button Animation
// Button.module.scss
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shaking-animation {
  animation: horizontal-shaking 0.3s linear;
}

.rotating-animation {
  animation: rotating 2s linear infinite;
}

.validate {
  color: #fff;
  background-color: tomato;
}

.ant-statistic-content-value {
  color: white !important;
  font-size: 52px !important;
}

// .customToolTip {
.ant-tooltip {
  z-index: 0 !important;
}
// }
