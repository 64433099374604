.framerContainer {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 1400px;
  // For centering on different screen sizes
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.innerBox,
.innerBoxSecondary,
.innerBoxTertiary,
.backgroundBox {
  position: absolute;
  left: 50%;

  transform: translateX(-50%) rotate(70deg) rotateX(41deg) rotateY(119deg);
  aspect-ratio: 1 / 1;
  overflow: visible;
}

.innerBox {
  width: 250px;
  height: 250px;
  border: 10px solid #fffb12;
  top: -2px;
  bottom: -2px;
  z-index: 1;
}

.innerBoxSecondary {
  width: 400px;
  height: 400px;
  border: 10px solid rgba(255, 255, 255, 0.2);
  top: -68px;
  bottom: -68px;
  z-index: 1;
}

.innerBoxTertiary {
  width: 550px;
  height: 550px;

  border: 10px solid #262626;
  top: -134px;
  bottom: -134px;

  z-index: 1;
}

.backgroundBox {
  width: 650px;
  height: 650px;
  background-color: #000;
  top: -14px;
  bottom: -156px;
  z-index: 6;
}

.framerRamp {
  position: absolute;
  width: 300px;
  height: 600px;
  background: linear-gradient(180deg, #000000 0%, #262626 100%);
  transform-origin: center;
  overflow: hidden;
  z-index: 2;
}

.framerRampLeft {
  transform: perspective(5000px) rotate(0deg) rotateX(50deg) rotateY(0deg);
  top: -96%;
  left: 33%;
}

.framerRampRight {
  transform: perspective(5000px) rotate(90deg) rotateX(50deg) rotateY(0deg);
  top: -18%;
  right: -68%;
}

@media (max-width: 1024px) {
  .framerContainer {
    height: 100%;
  }
  .innerBox {
    width: 200px;
    height: 200px;
    top: -1px;
    bottom: -1px;
  }
  // .framerStrip {
  //   width: 20%;
  // }
  .framerRampLeft {
    top: -121%;
    left: 37%;
  }

  .framerRampRight {
    top: -34%;
    right: -76%;
  }

  .framerRamp {
    width: 220px;
  }
  .innerBoxSecondary {
    width: 320px;
    height: 320px;
    top: -54px;
    bottom: -54px;
  }

  .innerBoxTertiary {
    width: 440px;
    height: 440px;
    top: -110px;
    bottom: -110px;
  }
  .framerStripContainer {
    width: 440px;
    height: 440px;
  }
  .backgroundBox {
    width: 540px;
    height: 540px;
  }
}

/* Small devices: Mobile phones */
@media (max-width: 768px) {
  .innerBox {
    width: 150px;
    height: 150px;
    top: 0;
    bottom: 0;
  }
  .framerRampLeft {
    top: -164%;
    left: 33%;
  }

  .framerRampRight {
    top: -57%;
    right: -95%;
  }
  .framerRamp {
    width: 170px;
  }

  .innerBoxSecondary {
    width: 240px;
    height: 240px;
    top: -40px;
    bottom: -40px;
  }

  .innerBoxTertiary {
    width: 330px;
    height: 330px;
    top: -85px;
    bottom: -85px;
  }
  .framerStripContainer {
    width: 330px;
    height: 330px;
  }
  .backgroundBox {
    width: 430px;
    height: 430px;
  }
}

@media (max-width: 480px) {
  .framerContainer {
    height: 100%;
  }
  .innerBox {
    width: 120px;
    height: 120px;
    top: 0;
    bottom: 0;
  }
  .framerStrip {
    width: 50%;
  }

  .innerBoxSecondary {
    width: 190px;
    height: 190px;
    top: -30px;
    bottom: -30px;
  }

  .innerBoxTertiary {
    width: 260px;
    height: 260px;
    top: -65px;
    bottom: -65px;
  }
  .framerRampLeft {
    top: -212%;
    left: 26%;
  }

  .framerRampRight {
    top: -82%;
    right: -118%;
  }

  .framerRamp {
    width: 150px;
  }
  .backgroundBox {
    width: 360px;
    height: 360px;
  }
}

.shimmer-effect {
  position: absolute;
  width: 150%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(161, 161, 161, 0.5) 10%,
    rgba(0, 0, 0, 0) 80%
  );
  transform: rotate(-20deg);
  animation: shimmer 2s infinite;
  animation-delay: var(--shimmer-delay, 0s);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(-20deg);
  }
  100% {
    transform: translateX(100%) rotate(-20deg);
  }
}
